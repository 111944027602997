.projects-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1220px;
  padding: 0 20px;
}

.bp3-tab:focus {
  outline: none;
}

.codeEditor .bp3-dialog-container {
  height: 100%;
  width: 100%;
}

div.ace_editor .ace_content {
  font-family: source-code-pro, Menlo, Consolas, 'Courier New', monospace !important;
}

.projects-loading {
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  bottom: 0;
  align-items: center;
  right: 0;
  left: 0;
  justify-content: center;
  z-index: 99999;

  &:before {
    content: '';
    background: #000;
    opacity: 0.3;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.projects-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  h1 {
    color: #e8e8e8;
  }
}

.savingSpinner {
  margin-left: 10px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -10px;
  justify-content: center;
}

.project {
  background: var(--mainDark);
  background-image: url(../assets/project-icon.png);
  background-repeat: no-repeat;
  background-size: 140px;
  background-position: -40px -20px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.4);
  padding: 26px;
  margin: 10px;
  max-width: 380px;
  position: relative;

  header {
    margin-bottom: 50px;
  }

  .projectMenuBtn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .break-flex {
    display: block;
  }

  h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 0.6;
    color: #f1f2f3;
    margin: 0;
  }

  .date-modif {
    font-size: 12px;
    display: block;
    color: #969696;
    margin-top: 6px;
  }

  .bp3-button:focus {
    outline: none;
  }

  .body {
    margin: -5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    a {
      margin: 5px;

      .bp3-button-text {
        display: block;
      }

      .label {
        display: block;
        text-align: center;
        margin-left: 5px;
      }
    }
  }
}

.inputWrapper {
  &:not(:first-of-type) {
    margin-top: 20px;
  }

  &:first-of-type {
    margin-top: 10px;
  }

  .inputLabel {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    line-height: 1.5;
    padding: 15px 10px;
    border: 1px solid var(--mainDark);
    color: #fff;
    background: var(--bgDark);
  }
}

ul li.endpoint {
  font-weight: bold;
  margin-bottom: 10px;
}

ul li.endpoint li {
  font-weight: normal;
}
