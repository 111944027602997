// Copyright 2018 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "../forms/common";

%pt-select {
  @include pt-button-base();
  @include pt-button();

  // stylelint-disable property-no-vendor-prefix
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: $pt-border-radius;
  height: $pt-button-height;
  padding: 0 ($input-padding-horizontal * 2.5) 0 $input-padding-horizontal;
  // fill parent container
  width: 100%;
  // stylelint-enable property-no-vendor-prefix
}

%pt-select-minimal {
  @include pt-button-minimal();
}

%pt-select-large {
  font-size: $pt-font-size-large;
  height: $pt-button-height-large;
  padding-right: $input-padding-horizontal * 3.5;
}

%pt-dark-select {
  @include pt-dark-button();
}

%pt-select-disabled {
  background-color: $button-background-color-disabled;
  box-shadow: none;
  color: $button-color-disabled;
  cursor: not-allowed;
}

%pt-select-arrow {
  color: $pt-icon-color;
  pointer-events: none;
  position: absolute;
  right: $input-padding-horizontal * 0.7;
  top: ($pt-button-height - $pt-icon-size-standard) / 2;

  &.#{$ns}-disabled {
    color: $pt-icon-color-disabled;
  }
}
