@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;500;600&display=swap');

@import './blueprintStyle/blueprint';
@import './assets/variables.scss';

body {
  background: #313642;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 395px;
}

body * {
  /*font-family: "Baloo Thambi 2", sans-serif;*/
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

button:focus,
input:focus {
  outline: none;
}

body,
html {
  height: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Consolas, 'Courier New', monospace;
}

#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 650px) {
    height: auto;
  }
}

.header {
  flex-grow: 0;
  flex-shrink: 1;
  padding: 10px 20px;
  background: var(--mainDark);
  border-bottom: 1px solid var(--accentDark);
  display: flex;
  align-items: center;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.logout {
  margin-left: auto;
  border: none;
  background: none;
  color: var(--mainBlue);
  display: block;
  transition: color 0.3s;

  &:hover {
    color: var(--lightBlue);
  }
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}

.content {
}
