:root {
  --lightBlue: #0099ff;
  --mainBlue: #0070bb;
  --mainBlueHover: #015d9a;

  --bgDark: #313642;
  --mainDark: #262d35;
  --accentDark: #3c414f;

  --transitionTime: 0.3s;
  --borderRadius: 3px;
}
