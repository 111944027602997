.plans {
  margin-bottom: 25px;

  h2 {
    margin: 0 0 5px;
    font-size: 22px;
  }

  :global(.bp3-button) {
    padding: 5px 11.7px;
  }

  :global(.bp3-button-text) {
    font-size: 12px;
  }
}
